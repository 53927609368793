export function addFactura(
  id,
  Numero,
  Sociedad,
  NomProveedor,
  Codigo,
  RFCEmisor,
  RFCReceptor,
  CPEmisor,
  CPReceptor,
  UUID,
  Entrada,
  Fecha,
  Total,
  FormaPago,
  TentativaPago,
  UsoPrincipal,
  MetodoPago,
  PDF,
  XML,
  Recepcion,
  Estatus,
  EstatusPago,
  FechaPago,
  Comentarios,
  BD,
  Moneda,
  Regimen
) {
  return {
    id,
    Numero,
    Sociedad,
    NomProveedor,
    Codigo,
    RFCEmisor,
    RFCReceptor,
    CPEmisor,
    CPReceptor,
    UUID,
    Entrada,
    Fecha,
    Total,
    FormaPago,
    TentativaPago,
    UsoPrincipal,
    MetodoPago,
    PDF,
    XML,
    Recepcion,
    Estatus,
    EstatusPago,
    FechaPago,
    Comentarios,
    BD,
    Moneda,
    Regimen,
  };
}

export const CeldasMisFacturas = [
  {
    id: "#",
    numeric: false,
    disablePadding: false,
    label: "#",
    minWidth: "2em",
  },
  {
    id: "Sociedad",
    numeric: false,
    disablePadding: true,
    label: "Sociedad",
    minWidth: "15em",
  },
  {
    id: "NomProveedor",
    numeric: false,
    disablePadding: true,
    label: "Nombre del proveedor",
    minWidth: "15em",
  },
  {
    id: "Codigo",
    numeric: false,
    disablePadding: true,
    label: "Código",
    minWidth: "10em",
  },
  {
    id: "RFC",
    numeric: false,
    disablePadding: true,
    label: "RFC",
    minWidth: "10em",
  },
  {
    id: "UUID",
    numeric: false,
    disablePadding: true,
    label: "UUID folio fiscal",
    minWidth: "21em",
  },
  {
    id: "Entrada",
    numeric: true,
    disablePadding: false,
    label: "Entrada",
    minWidth: "8em",
  },
  {
    id: "Fecha",
    numeric: false,
    disablePadding: true,
    label: "Fecha timbrado",
    minWidth: "12em",
  },
  {
    id: "Total",
    numeric: true,
    disablePadding: false,
    label: "Total",
    minWidth: "10em",
  },
  {
    id: "FormaPago",
    numeric: false,
    disablePadding: true,
    label: "Forma de pago",
    minWidth: "12em",
  },
  {
    id: "UsoPrincipal",
    numeric: false,
    disablePadding: true,
    label: "Uso principal",
    minWidth: "13em",
  },

  {
    id: "MetodoPago",
    numeric: false,
    disablePadding: true,
    label: "Método de pago",
    minWidth: "13em",
  },
  {
    id: "Tentativa",
    numeric: false,
    disablePadding: true,
    label: "Tentativa de pago",
    minWidth: "13em",
  },
  {
    id: "PDF",
    numeric: false,
    disablePadding: true,
    label: "PDF",
    minWidth: "100%",
  },

  {
    id: "XML",
    numeric: false,
    disablePadding: true,
    label: "XML",
    minWidth: "100%",
  },
  {
    id: "Recepcion",
    numeric: false,
    disablePadding: true,
    label: "Recepción",
  },
  {
    id: "Estatus",
    numeric: false,
    disablePadding: true,
    label: "Estatus",
    minWidth: "10em",
  },
];
