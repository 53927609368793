export function addEmpleado(
  id,
  IdEmpleado,
  rolEmpleado,
  Nombre,
  Apellidos,
  Correo,
  IdArea,
  Area,
  Estatus,
  Contraseña,
  Usuario,
  ViDocPen
) {
  return {
    id,
    IdEmpleado,
    rolEmpleado,
    Nombre,
    Apellidos,
    Correo,
    IdArea,
    Area,
    Estatus,
    Contraseña,
    Usuario,
    ViDocPen,
  };
}

export const CeldasEmpleados = [
  {
    id: "Usuario",
    align: "Left",

    label: "Usuario",
    minWidth: "1em",
  },
  {
    id: "Rol",
    align: "Left",

    label: "Rol",
    minWidth: "5em",
  },
  {
    id: "Nombre",
    align: "Left",

    label: "Nombre",
    minWidth: "5em",
  },
  {
    id: "Apellidos",
    align: "Left",

    label: "Apellidos",
    minWidth: "5em",
  },
  {
    id: "Correo",
    align: "Left",

    label: "Correo",
    minWidth: "5em",
  },
  {
    id: "Area",
    align: "Left",

    label: "Área",
    minWidth: "5em",
  },
  {
    id: "Estatus",
    align: "Left",

    label: "Estatus",
    minWidth: "5em",
  },
];
