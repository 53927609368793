import React from "react";
import PropTypes from "prop-types";
import { AppBar, Drawer } from "./Header-css";
import {
  Toolbar,
  IconButton,
  Button,
  Typography,
  Badge,
  Menu,
  MenuItem,
  List,
  Divider,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { Menus } from "../Data/Menus";
import PortalProveedores from "../../Assets/Encabezado.png";
import Proveedor from "../../Assets/Proveedor.png";
import Empleado from "../../Assets/Empleado.png";
import Cookies from "js-cookie";
import { isAuthenticatedvalid } from "../Router/Cookies";

export function Header(props) {
  let { IsSession, open, setOpen, usuario } = props;

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const OnButtonClick = (event) => {
    if (event.target.id === "BtnHome") {
      navigate("/Home");
    }
  };

  const AbrirMenu = () => {
    if (IsSession === true) {
      props.setOpen(!open);
    }
  };

  const handleMenu = (event) => {
    if (!isAuthenticatedvalid("access")) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    if (event.target.id === "MItPerfil") {
      navigate("/Perfil");
    } else if (event.target.id === "MItHome") {
      navigate("/Home");
    } else if (event.target.id === "MItCerrarSesion") {
      Cookies.remove("access");
      Cookies.remove("operador");
      Cookies.remove("usuario");
      Cookies.remove("tipoEmpleado");
      Cookies.remove("ViDocPen");
      setOpen(false);
      navigate("/");
    }
  };

  return (
    <div>
      <AppBar position="absolute" open={open} color="inherit">
        <Toolbar>
          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={AbrirMenu}
            sx={{
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography align="center" sx={{ flexGrow: 1 }} id="BtnHome">
            {isAuthenticatedvalid("access") === true ? (
              <Button onClick={OnButtonClick} id="BtnHome">
                <img
                  src={PortalProveedores}
                  alt="Portal Proveedores"
                  width="60%"
                  height="auto"
                  align="center"
                  id="BtnHome"
                />
              </Button>
            ) : (
              <img
                src={PortalProveedores}
                alt="Portal Proveedores"
                width="23%"
                height="auto"
                align="center"
                id="BtnHome"
              />
            )}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            {usuario}
          </Typography>
          <IconButton color="inherit" onClick={handleMenu}>
            {isAuthenticatedvalid("access") && (
              <Badge color="secondary">
                {Cookies.get("operador") === "Empleado" ? (
                  <img src={Empleado} alt="Empleado" width={"50px"} />
                ) : (
                  <img src={Proveedor} alt="Proveedor" width={"50px"} />
                )}
              </Badge>
            )}
          </IconButton>
        </Toolbar>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem id="MItPerfil" onClick={handleClose}>
            Perfil
          </MenuItem>
          <MenuItem id="MItHome" onClick={handleClose}>
            Home
          </MenuItem>
          <MenuItem id="MItCerrarSesion" onClick={handleClose}>
            Cerrar sesión
          </MenuItem>
        </Menu>
      </AppBar>
    </div>
  );
}

export function MenuLateral(props) {
  let { open, operador, IsSession } = props;

  const AbrirMenu = () => {
    if (IsSession === true) {
      props.setOpen(!open);
    }
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <IconButton color="primary" onClick={AbrirMenu}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      {IsSession && (
        <List component="nav">
          <ListaMenus operador={operador} />
        </List>
      )}
      <Divider />
    </Drawer>
  );
}

export function ListaMenus(props) {
  const navigate = useNavigate();

  const handleListItemClick = (event) => {
    if (event.target.id === "btnCatEmp") {
      navigate("/Catalogo-Empleados");
    } else if (event.target.id === "btnCatPro") {
      navigate("/Catalogo-Proveedores");
    } else if (event.target.id === "btnCatSoc") {
      navigate("/Catalogo-Sociedades");
    } else if (event.target.id === "btnFacPen") {
      navigate("/Facturas-Pendientes");
    } else if (event.target.id === "btnMisFac") {
      navigate("/MisFacturas");
    } else if (event.target.id === "btnEntMer") {
      navigate("/Entradas-Mercancia");
    } else if (event.target.id === "btnPedi") {
      navigate("/Pedidos");
    } else if (event.target.id === "btnConPag") {
      navigate("/Pagos");
    } else if (event.target.id === "btnCoPaPe") {
      navigate("/Pagos-Pendientes");
    }
  };

  const listMenus = Menus.map((item) =>
    Cookies.get("operador") === "Empleado"
      ? true === item.Empleado &&
        (item.idName === "btnFacPen" || item.idName === "btnCoPaPe" ? (
          Cookies.get("ViDocPen") === "Y" && (
            <ListItemButton
              key={item.id}
              id={item.idName}
              onClick={(event) => handleListItemClick(event, item.id)}
            >
              <ListItemIcon id={item.idName}>
                <img
                  src={item.Imagen}
                  id={item.idName}
                  alt="Proveedor"
                  width={"45em"}
                />
              </ListItemIcon>
              {item.name}
            </ListItemButton>
          )
        ) : (
          <ListItemButton
            key={item.id}
            id={item.idName}
            onClick={(event) => handleListItemClick(event, item.id)}
          >
            <ListItemIcon id={item.idName}>
              <img
                src={item.Imagen}
                id={item.idName}
                alt="Proveedor"
                width={"45em"}
              />
            </ListItemIcon>
            {item.name}
          </ListItemButton>
        ))
      : true === item.Proveedor && (
          <ListItemButton
            key={item.id}
            id={item.idName}
            onClick={(event) => handleListItemClick(event, item.id)}
          >
            <ListItemIcon id={item.idName}>
              <img
                src={item.Imagen}
                id={item.idName}
                alt="Proveedor"
                width={"45em"}
              />
            </ListItemIcon>
            {item.name}
          </ListItemButton>
        )
  );

  return <React.Fragment>{listMenus}</React.Fragment>;
}

Header.propTypes = {
  datos: PropTypes.object,
};
