import React from "react";
import PropTypes from "prop-types";
import { useNavigate, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Grid, Toolbar } from "@mui/material";
import { theme, StyledPaper, Styleddiv, StyledButton } from "./Login-css";
import { Header, MenuLateral } from "../Header/Header";
import TextField from "@mui/material/TextField";
import Proveedor from "../../Assets/Proveedor.png";
import Empleado from "../../Assets/Empleado.png";
import {
  Link,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Alert,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Cookies from "js-cookie";
import { isAuthenticatedvalid } from "../Router/Cookies";

function Login(props) {
  const operador = Cookies.get("operador");
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorUsuario, setErrorUsuario] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState(false);
  const [errorSession, setErrorSession] = React.useState(false);
  const [error, setError] = React.useState("");
  const IsOperador = !!Cookies.get("operador");
  const navigate = useNavigate();

  let IsSession = isAuthenticatedvalid("access");

  const [values, setValues] = React.useState({
    usuario: "",
    password: "",
    operador: operador,
  });

  const handleShowPassword = () => setShowPassword((show) => !show);

  const Peticion = new Request("/api/login", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(values),
  });

  async function handleSubmit(e) {
    e.preventDefault();

    if (values.Usuario === "") {
      setErrorUsuario(true);
      return;
    }
    setErrorUsuario(false);

    if (values.password === "") {
      setErrorPassword(true);
      return;
    }
    setErrorPassword(false);

    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      console.log(data);
      if (data.status === "success") {
        if (data.data.perfil === "A") {
          Cookies.set("tipoEmpleado", "Administrador");
        } else {
          Cookies.set("tipoEmpleado", "General");
        }
        Cookies.set("usuario", data.data.nombre);
        Cookies.set("access", data.data.token);
        Cookies.set("id", data.data.id);
        Cookies.set("ViDocPen", data.data.VisuDocPen);
        navigate("/Home");
      } else {
        setError(data.message);
        setErrorSession(true);
      }
    } catch (error) {
      console.log("Failed to fetch from login", error);
    }
  }

  React.useEffect(() => {
    if (IsSession === true) {
      navigate("/Home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(evt) {
    const { target } = evt;
    const { name, value } = target;
    setErrorSession(false);
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
  }

  if (!IsOperador) {
    return <Navigate to="/" />;
  }
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Header IsSession={false} open={props.open} setOpen={props.setOpen} />
        <MenuLateral
          IsSession={false}
          open={props.open}
          setOpen={props.setOpen}
        />
        <Box>
          <Toolbar>
            <Grid container justifyContent="center">
              <StyledPaper variant="elevation" elevation={20} square={false}>
                <form onSubmit={handleSubmit}>
                  {operador === "Empleado" ? (
                    <div>
                      <img src={Empleado} alt="Proveedor" width={"150px"} />
                    </div>
                  ) : (
                    <div>
                      <img src={Proveedor} alt="Proveedor" width={"150px"} />
                    </div>
                  )}
                  <TextField
                    autoComplete="Login-Usuario"
                    size="small"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="usuario"
                    label={operador === "Empleado" ? "Usuario*" : "RFC*"}
                    name="usuario"
                    autoFocus
                    value={values.usuario}
                    onChange={handleChange}
                    error={errorUsuario}
                  />
                  <FormControl fullWidth variant="outlined">
                    <InputLabel required size="small">
                      Contaseña
                    </InputLabel>
                    <OutlinedInput
                      error={errorPassword}
                      size="small"
                      autoComplete="Login-password"
                      variant="outlined"
                      fullWidth
                      name="password"
                      label="Contraseña"
                      id="password"
                      value={values.password}
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Styleddiv>
                      <Link
                        variant="body2"
                        onClick={() => {
                          navigate("/AvisoDePrivacidad");
                        }}
                      >
                        Aviso de privacidad
                      </Link>
                      {/* <Link href="/AvisoDePrivacidad" variant="body2">
                        Aviso de privacidad
                      </Link> */}
                    </Styleddiv>
                    <br />
                    <Styleddiv>
                      <StyledButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Ingresar
                      </StyledButton>
                    </Styleddiv>
                    <Styleddiv>
                      <Link
                        variant="body2"
                        onClick={() => {
                          navigate("/RecuperarPassword");
                        }}
                      >
                        Olvidaste tu contraseña?
                      </Link>
                      {/* <Link href="/RecuperarPassword" variant="body2">
                        Olvidaste tu contraseña?
                      </Link> */}
                    </Styleddiv>
                  </FormControl>
                </form>
              </StyledPaper>
            </Grid>
          </Toolbar>
        </Box>
      </Box>
      {errorSession === true && (
        <Alert severity="error">Error al iniciar la sesión,{error}</Alert>
      )}
    </ThemeProvider>
  );
}

Login.propTypes = {
  datos: PropTypes.object,
};

export default Login;
