import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { Header, MenuLateral } from "../Header/Header";
import {
  Box,
  Toolbar,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";
import {
  theme,
  StyledButton,
  StylePaperPrincipal,
  StyledDiv,
  StyledTheme,
  StylePaperButton,
  StyledInputFile,
  StyledButtonEditar,
} from "./Styled-MisFacturas";
import Cookies from "js-cookie";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { convertToBase64 } from "../Data/ArchivoBase64";
import createFile from "../Data/ArchivoBase64";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { FormatoMoneda } from "../Data/DatosSAT";
import moment from "moment";
import { parseString } from "xml2js";

function DetalleFactura(props) {
  let params = useParams();
  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const access = Cookies.get("access");
  const id = Cookies.get("id");
  let IsSession = !!Cookies.get("access");

  const [values, setValues] = React.useState({
    IdFactura: "",
    id: id,
    usuario: usuario,
    operador: operador,
    RazonSocial: "",
    EntradaMercancia: "",
    PDF: "",
    PDF64: "",
    XML: "",
    XML64: "",
    Recepcion: "",
    Recepcion64: "",
    FolioFactura: "",
    RFCEmisor: "",
    RFCReceptor: "",
    NombreEmisor: "",
    NombreReceptor: "",
    UUID: "",
    FechaFactura: "",
    Total: "",
    FormaPago: "",
    UsoPrincipal: "",
    TotalResp: "",
    FormaPagoResp: "",
    UsoPrincipalResp: "",
    MetodoPago: "",
    Estatus: "",
    EstatusPago: "",
    FechaPago: "",
    Comentarios: "",
    BD: "",
    CPReceptor: "",
    CPEmisor: "",
  });

  const [habilitar, setHabilitar] = React.useState(false);
  const [Generacion, setGeneracion] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [habilarBoton, setHabilarBoton] = React.useState(false);
  const [datosProveedor, setDatosProveedor] = React.useState(false);
  const [sociedades, setSociedades] = React.useState([]);
  const [entradas, setEntradas] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [errorMensaje, setErrorMensaje] = React.useState([]);
  const navigate = useNavigate();

  const getDatosProveedor = async (setDatosProveedor) => {
    const Values = {
      usuario: id,
      operador: operador,
    };
    const Peticion = new Request("/api/facturas/getProveedor", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      console.log(data.data.length);
      if (data.data.length > 0) {
        setDatosProveedor(false);
      } else {
        setDatosProveedor(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSociedades = async (setSociedades) => {
    const Values = {
      usuario: id,
      operador: operador,
    };
    const Peticion = new Request("/api/facturas/getSociedades", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      setSociedades(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getEntradas = async (setEntradas, BD, Entrada) => {
    const Values = {
      usuario: id,
      operador: operador,
      BD: BD,
      tipo: Entrada,
    };
    const Peticion = new Request("/api/facturas/getEntradas", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      setEntradas(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getDatosProveedor(setDatosProveedor);
    getSociedades(setSociedades);

    if (props.misFacturas.length > 0) {
      let fechaString = props.misFacturas[params.id - 1].Fecha;
      console.log(fechaString);
      let fecha = moment(fechaString, "DD-MM-YYYY");
      console.log(fecha);
      let fechaFormateada = fecha.format("YYYY-MM-DD");
      console.log(fechaFormateada);
      setValues({
        IdFactura: props.misFacturas[params.id - 1].Numero,
        RazonSocial: props.misFacturas[params.id - 1].Sociedad,
        EntradaMercancia: props.misFacturas[params.id - 1].Entrada,
        //id: id,
        id: props.misFacturas[params.id - 1].NomProveedor,
        usuario: usuario,
        operador: operador,
        Recepcion64: props.misFacturas[params.id - 1].Recepcion,
        Recepcion: props.misFacturas[params.id - 1].Recepcion
          ? "Cargado"
          : "No cargado",
        XML64: props.misFacturas[params.id - 1].XML,
        XML: props.misFacturas[params.id - 1].XML ? "Cargado" : "No cargado",
        PDF64: props.misFacturas[params.id - 1].PDF,
        PDF: props.misFacturas[params.id - 1].PDF ? "Cargado" : "No cargado",
        FolioFactura: props.misFacturas[params.id - 1].Codigo,
        RFC: props.misFacturas[params.id - 1].RFC,
        UUID: props.misFacturas[params.id - 1].UUID,
        FechaFactura: fechaFormateada,
        Total: parseFloat(
          props.misFacturas[params.id - 1].Total.replace(/,/g, "")
        ),
        FormaPago: props.misFacturas[params.id - 1].FormaPago,
        UsoPrincipal: props.misFacturas[params.id - 1].UsoPrincipal,

        MetodoPago: props.misFacturas[params.id - 1].MetodoPago,
        Estatus: props.misFacturas[params.id - 1].Estatus,
        EstatusPago: props.misFacturas[params.id - 1].EstatusPago,
        FechaPago: props.misFacturas[params.id - 1].FechaPago,
        Comentarios: props.misFacturas[params.id - 1].Comentarios,
        BD: props.misFacturas[params.id - 1].BD,

        RFCEmisor: props.misFacturas[params.id - 1].RFCEmisor,
        RFCReceptor: props.misFacturas[params.id - 1].RFCReceptor,
        NombreEmisor: usuario,
        NombreReceptor: props.misFacturas[params.id - 1].Sociedad,
        CPEmisor: props.misFacturas[params.id - 1].CPEmisor,
        CPReceptor: props.misFacturas[params.id - 1].CPReceptor,
        Regimen: props.misFacturas[params.id - 1].Regimen,
      });
      setHabilitar(true);

      if (
        props.misFacturas[params.id - 1].Estatus === "Aceptada" ||
        props.misFacturas[params.id - 1].Estatus === "Cancelado"
      ) {
        setHabilarBoton(true);
      }

      getEntradas(
        setEntradas,
        props.misFacturas[params.id - 1].BD,
        props.misFacturas[params.id - 1].Entrada
      );
    } else {
      navigate("/MisFacturas");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addAlert = async (message, severity) => {
    setErrorMensaje((prevAlerts) => [...prevAlerts, { message, severity }]);
    setOpenMessage(true);
  };

  const Validaciones = async () => {
    let err = "";
    let Valerr = false;
    //Razon social

    if (values.usuario !== values.NombreEmisor) {
      err = "El nombre de proveedor de la entrada no es el mismo del XML";
      Valerr = true;
      addAlert(err, "error");
    }
    sociedades.forEach((row) => {
      if (row.BDSAP === values.RazonSocial) {
        if (row.NomSociedad !== values.NombreReceptor) {
          err =
            "El nombre del receptor no es mismo de la sociedad seleccionada en el XML";
          Valerr = true;
          addAlert(err, "error");
        }
      }
    });

    entradas.forEach((row) => {
      if (row.DocNum === values.EntradaMercancia) {
        if (
          FormatoMoneda(parseFloat(row.DocTotal)) !==
          FormatoMoneda(parseFloat(values.Total))
        ) {
          err = "El total de la entrada no es el mismo del XML";
          Valerr = true;
          addAlert(err, "error");
        }
        if (row.PeyMethod !== values.FormaPago) {
          err = "La forma de pago de la entrada no es el mismo del XML";
          Valerr = true;
          addAlert(err, "error");
        }
        if (row.U_B1SYS_MainUsage !== values.UsoPrincipal) {
          err = "El uso principal de la entrada no es el mismo del XML";
          Valerr = true;
          addAlert(err, "error");
        }
      }
    });

    return Valerr;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMensaje([]);
    if (datosProveedor === false) {
      addAlert(
        "Revisar el estatus de su registro ya que debe de cargar o actualizar los archivos en tu perfil para poder cargar facturas",
        "error"
      );
      setError(true);
      setOpenMessage(true);
      return;
    }

    setError(false);
    setOpenMessage(false);

    const Validacion = await Validaciones();

    if (!Validacion) {
      const Peticion = new Request("/api/facturas/patchfactura", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": access,
        },
        method: "PATCH",
        body: JSON.stringify(values),
      });

      try {
        const response = await fetch(Peticion);
        const data = await response.json();
        if (data.status === "success") {
          setHabilarBoton(true);
          setGeneracion(true);
          setOpenMessage(true);
          setError(false);
        } else {
          setError(true);
          setOpenMessage(true);
          addAlert(data.message, "error");
        }
      } catch (error) {
        console.error("Failed to fetch from updateSociedad", error);
      }
    } else {
      setError(true);
      setOpenMessage(true);
    }
  }

  function handleChange(evt) {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
  }

  const Archivos = async (evt) => {
    const { target } = evt;
    const { name, files, id } = target;

    if (files[0] === undefined) {
      return;
    }

    var base64;
    try {
      const base64pdf = await convertToBase64(files[0]);
      var ArrayAuxiliar = [];
      ArrayAuxiliar = base64pdf.split(",");
      base64 = ArrayAuxiliar[1];
    } catch (error) {
      console.error("Error while converting to Base64:", error);
    }

    const newValues = {
      ...values,
      [name]: files[0].name,
      [id]: base64,
    };
    setValues(newValues);
  };

  const ArchivoXML = async (evt) => {
    const { target } = evt;
    const { name, files, id } = target;

    if (files[0] === undefined) {
      return;
    }

    var base64;
    try {
      const base64pdf = await convertToBase64(files[0]);
      var ArrayAuxiliar = [];
      ArrayAuxiliar = base64pdf.split(",");
      base64 = ArrayAuxiliar[1];
    } catch (error) {
      console.error("Error while converting to Base64:", error);
    }

    const file = evt.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const xmlContent = event.target.result;
        parseXML(xmlContent, name, file.name, base64, id);
      };
      reader.readAsText(file);
    }

    // Restablecer el input para permitir seleccionar el mismo archivo nuevamente
    evt.target.value = null;
  };

  const parseXML = (xmlContent, name, FileName, Base64, id) => {
    // Parse the XML string into a JavaScript object
    parseString(xmlContent, { explicitArray: false }, (err, result) => {
      if (err) {
        console.error("Error parsing XML:", err);
        return;
      }

      try {
        if (
          result["cfdi:Comprobante"].$ === undefined ||
          result["cfdi:Comprobante"].$.Fecha === undefined ||
          result["cfdi:Comprobante"].$.Folio === undefined ||
          result["cfdi:Comprobante"].$.LugarExpedicion === undefined ||
          result["cfdi:Comprobante"].$.Total === undefined ||
          result["cfdi:Comprobante"].$.FormaPago === undefined ||
          result["cfdi:Comprobante"].$.MetodoPago === undefined ||
          result["cfdi:Comprobante"]["cfdi:Emisor"].$.Rfc === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.Rfc === undefined ||
          result["cfdi:Comprobante"]["cfdi:Emisor"].$.Nombre === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.Nombre === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$
            .DomicilioFiscalReceptor === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.UsoCFDI === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$
            .RegimenFiscalReceptor === undefined ||
          result["cfdi:Comprobante"]["cfdi:Complemento"][
            "tfd:TimbreFiscalDigital"
          ].$.UUID === undefined
        ) {
          addAlert(
            "No cuenta con la estructura de un XML valida de un timbre del SAT",
            "error"
          );
          setError(true);
          setOpenMessage(true);
          return;
        }
      } catch (error) {
        addAlert(
          "No cuenta con la estructura de un XML valida de un timbre del SAT",
          "error"
        );
        setError(true);
        setOpenMessage(true);
        return;
      }

      let Retenciones = 0;

      if (
        result["cfdi:Comprobante"]["cfdi:Impuestos"].$
          .TotalImpuestosRetenidos !== undefined
      ) {
        Retenciones =
          result["cfdi:Comprobante"]["cfdi:Impuestos"].$
            .TotalImpuestosRetenidos;
      }

      let Descuentos = 0;

      if (result["cfdi:Comprobante"].$.Descuento !== undefined) {
        Descuentos = result["cfdi:Comprobante"].$.Descuento;
      }

      const newValues = {
        ...values,
        [name]: FileName,
        [id]: Base64,
        FechaFactura: result["cfdi:Comprobante"].$.Fecha,
        FolioFactura: result["cfdi:Comprobante"].$.Folio,
        Total:
          parseFloat(result["cfdi:Comprobante"].$.Total) +
          parseFloat(Retenciones) +
          parseFloat(Descuentos),
        FormaPago: result["cfdi:Comprobante"].$.FormaPago,
        MetodoPago: result["cfdi:Comprobante"].$.MetodoPago,
        RFCEmisor: result["cfdi:Comprobante"]["cfdi:Emisor"].$.Rfc,
        RFCReceptor: result["cfdi:Comprobante"]["cfdi:Receptor"].$.Rfc,
        NombreEmisor: result["cfdi:Comprobante"]["cfdi:Emisor"].$.Nombre,
        NombreReceptor: result["cfdi:Comprobante"]["cfdi:Receptor"].$.Nombre,
        UsoPrincipal: result["cfdi:Comprobante"]["cfdi:Receptor"].$.UsoCFDI,
        UUID: result["cfdi:Comprobante"]["cfdi:Complemento"][
          "tfd:TimbreFiscalDigital"
        ].$.UUID,
        CPReceptor:
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.DomicilioFiscalReceptor,
        CPEmisor: result["cfdi:Comprobante"].$.LugarExpedicion,
        Regimen:
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.RegimenFiscalReceptor,
      };
      setValues(newValues);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGeneracion(false);
    setOpenMessage(false);

    if (Generacion === true) {
      navigate("/MisFacturas");
    }
  };

  if (IsSession) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Header
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
            operador={Cookies.get("operador")}
            usuario={Cookies.get("usuario")}
          />
          <MenuLateral
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 10,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Typography
              sx={{ my: 3, mx: 2 }}
              align="center"
              variant="h5"
              component="h5"
            >
              Datos generales
            </Typography>

            <form onSubmit={handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperPrincipal variant="outlined">
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Razón social:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-factura-detalle-RazonSocial"
                      size="small"
                      variant="outlined"
                      required
                      id="RazonSocial"
                      name="RazonSocial"
                      autoFocus
                      value={values.RazonSocial}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Proveedor:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-DetalleFactura-id"
                      size="small"
                      variant="outlined"
                      required
                      id="id"
                      name="id"
                      autoFocus
                      value={values.id}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Folio:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-DetalleFactura-FolioFactura"
                      size="small"
                      variant="outlined"
                      required
                      id="FolioFactura"
                      name="FolioFactura"
                      value={values.FolioFactura}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Fecha:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-DetalleFactura-FechaFactura"
                      size="small"
                      variant="outlined"
                      required
                      id="FechaFactura"
                      name="FechaFactura"
                      value={values.FechaFactura}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Estatus:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-DetalleFactura-Estatus"
                      size="small"
                      variant="outlined"
                      required
                      id="Estatus"
                      name="Estatus"
                      value={values.Estatus}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Entrada:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-DetalleFactura-EntradaMercancia"
                      size="small"
                      variant="outlined"
                      required
                      id="EntradaMercancia"
                      name="EntradaMercancia"
                      value={values.EntradaMercancia}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Estatus del pago:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-DetalleFactura-EstatusPago"
                      size="small"
                      variant="outlined"
                      required
                      id="EstatusPago"
                      name="EstatusPago"
                      value={values.EstatusPago}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Fecha Pago:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-DetalleFactura-FechaPago"
                      size="small"
                      variant="outlined"
                      required
                      id="FechaPago"
                      name="FechaPago"
                      value={values.FechaPago}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Comentarios:
                    </Typography>
                    <TextField
                      autoComplete="section-DetalleFactura-Comentarios"
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      id="Comentarios"
                      name="Comentarios"
                      value={values.Comentarios}
                      onChange={handleChange}
                      multiline={true}
                      rows={3}
                      disabled={habilitar}
                    />
                  </StyledDiv>

                  {/* PDF */}

                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *PDF
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-DetalleFactura-PDF"
                      size="small"
                      variant="outlined"
                      required
                      id="LblPDF"
                      name="LblPDF"
                      value={values.PDF}
                      disabled={true}
                    />
                    {values.Estatus !== "Aceptada" ? (
                      <StyledButtonEditar
                        size="medium"
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        endIcon={<CloudUploadIcon />}
                        id="BtnPDF"
                        name="BtnPDF"
                        onChange={Archivos}
                        multiple={false}
                      >
                        cargar
                        <StyledInputFile
                          id="PDF64"
                          name="PDF"
                          type="file"
                          accept="application/pdf"
                        />
                      </StyledButtonEditar>
                    ) : (
                      values.PDF64 !== "" && (
                        <StyledButtonEditar
                          size="medium"
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          endIcon={<PictureAsPdfIcon />}
                          id="PDF"
                          name="PDF"
                          onClick={(e) =>
                            createFile(values.PDF64, "application/pdf")
                          }
                        >
                          Ver
                        </StyledButtonEditar>
                      )
                    )}
                  </StyledDiv>
                  {/* XML  */}
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *XML:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-DetalleFactura-XML"
                      size="small"
                      variant="outlined"
                      required
                      id="LblXML"
                      name="LblXML"
                      value={values.XML}
                      disabled={true}
                    />
                    {values.Estatus !== "Aceptada" ? (
                      <StyledButtonEditar
                        size="medium"
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        endIcon={<CloudUploadIcon />}
                        id="BtnXML"
                        name="BtnXML"
                        onChange={ArchivoXML}
                        multiple={false}
                      >
                        cargar
                        <StyledInputFile
                          id="XML64"
                          name="XML"
                          type="file"
                          accept="text/xml"
                        />
                      </StyledButtonEditar>
                    ) : (
                      values.XML64 !== "" && (
                        <StyledButtonEditar
                          size="medium"
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          endIcon={<PictureAsPdfIcon />}
                          id="PDF"
                          name="PDF"
                          onClick={(e) => createFile(values.XML64, "text/xml")}
                        >
                          Ver
                        </StyledButtonEditar>
                      )
                    )}
                  </StyledDiv>
                  {/* Recepción  */}
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Recepción:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-DetalleFactura-Recepcion"
                      size="small"
                      variant="outlined"
                      required
                      id="LblRecepcion"
                      name="LblRecepcion"
                      value={values.Recepcion}
                      disabled={true}
                    />
                    {values.Estatus !== "Aceptada" ? (
                      <StyledButtonEditar
                        size="medium"
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        endIcon={<CloudUploadIcon />}
                        id="BtnRecepcion"
                        name="BtnRecepcion"
                        onChange={Archivos}
                        multiple={false}
                      >
                        cargar
                        <StyledInputFile
                          id="Recepcion64"
                          name="Recepcion"
                          type="file"
                          accept="image/gif, image/jpeg, image/png"
                        />
                      </StyledButtonEditar>
                    ) : (
                      values.Recepcion64 !== "" && (
                        <StyledButtonEditar
                          size="medium"
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          endIcon={<PictureAsPdfIcon />}
                          id="PDF"
                          name="PDF"
                          onClick={(e) =>
                            createFile(
                              values.Recepcion64,
                              "image/gif, image/jpeg, image/png"
                            )
                          }
                        >
                          Ver
                        </StyledButtonEditar>
                      )
                    )}
                  </StyledDiv>
                </StylePaperPrincipal>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperButton variant="elevation" elevation={0}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={StyledTheme.submit}
                    disabled={habilarBoton}
                  >
                    Actualizar
                  </StyledButton>
                </StylePaperButton>
              </Grid>
            </form>
          </Box>
        </Box>
        {Generacion === true && (
          <Snackbar
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            open={openMessage}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Se actualizo con exito la factura con folio {values.FolioFactura}!
            </Alert>
          </Snackbar>
        )}

        {error === true && (
          <Snackbar
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            open={openMessage}
            autoHideDuration={30000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error" variant="filled">
              {errorMensaje.length > 0 &&
                errorMensaje.map((entrada) => {
                  return <div>{entrada.message}</div>;
                })}
              {/* {errorMensaje.length > 0 ? errorMensaje[0].message : ""} */}
            </Alert>
            {/* <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {errorMensaje}
            </Alert> */}
          </Snackbar>
        )}
      </ThemeProvider>
    );
  } else {
    return <Navigate to="/" />;
  }
}

DetalleFactura.propTypes = {
  sociedades: PropTypes.array,
};

export default DetalleFactura;
