import React from "react";
import PropTypes from "prop-types";
import { useNavigate, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Grid, Toolbar, Button, Typography } from "@mui/material";
import { Header } from "../Header/Header";
import { theme, Item } from "./Home-css";
import Cookies from "js-cookie";
import { Menus } from "../Data/Menus";
import { UpdateAccessToken } from "../Router/Cookies";
import ImgCatEmp from "../../Assets/catalogoEmpleados.png";
import ImgCatProv from "../../Assets/CatalogoPro.png";
import ImgCatSoc from "../../Assets/CatalogoSoc.png";
import ImgPedi from "../../Assets/Pedidos.png";
import ImgEntradas from "../../Assets/Entrada.png";
import ImgFacPen from "../../Assets/FacturasPen.png";
import ImgMisFac from "../../Assets/MisFacturas.png";
import ImgPagoPendiente from "../../Assets/Pagos.png";
import ImgPago from "../../Assets/ComplementoPago.png";
import TablaSociedadesDetalles from "./Sociedades/TablaSociedadesDetalles";
import { addSociedad } from "../Data/CtaSociedades-Data";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

function Home(props) {
  const navigate = useNavigate();
  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const access = Cookies.get("access");
  const id = Cookies.get("id");
  const validacion = Cookies.get("ViDocPen");
  const [sociedades, setSociedades] = React.useState([]);

  const [product, setProduct] = React.useState(Menus);

  const OnButtonClick = (event) => {
    if (event.target.id === "btnCatEmp") {
      navigate("/Catalogo-Empleados");
    } else if (event.target.id === "btnCatPro") {
      navigate("/Catalogo-Proveedores");
    } else if (event.target.id === "btnCatSoc") {
      navigate("/Catalogo-Sociedades");
    } else if (event.target.id === "btnFacPen") {
      navigate("/Facturas-Pendientes");
    } else if (event.target.id === "btnMisFac") {
      navigate("/MisFacturas");
    } else if (event.target.id === "btnEntMer") {
      navigate("/Entradas-Mercancia");
    } else if (event.target.id === "btnPedi") {
      navigate("/Pedidos");
    } else if (event.target.id === "btnConPag") {
      navigate("/Pagos");
    } else if (event.target.id === "btnCoPaPe") {
      navigate("/Pagos-Pendientes");
    }
  };

  const getSociedades = async (setSociedades) => {
    setSociedades([]);
    const Values = {
      usuario: id,
      operador: operador,
      dato: "SN",
    };
    const PeticionSociedades = new Request("/api/home/getSociedades", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });

    let DatosArray = [];
    try {
      const response = await fetch(PeticionSociedades);
      const data = await response.json();
      const Datos = data.data;

      Datos.map((item) =>
        DatosArray.push(
          addSociedad(
            item.Fila,
            item.IdSociedad,
            item.NomSociedad,
            item.RFC,
            item.Regimen,
            item.CP,
            item.Estatus === "A" ? "Activo" : "Inactivo",
            item.UsuarioSAP,
            item.PassSAP,
            item.BDSAP,
            "Nombre.pdf",
            item.Constancia ? item.Constancia : ""
          )
        )
      );

      setSociedades(DatosArray);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (operador === "Proveedor") {
      getSociedades(setSociedades);
    }

    const fetchData = async (params) => {
      if (
        params.name !== "" &&
        params.name !== "Catálogo de empleados" &&
        params.name !== "Catálogo de proveedores" &&
        params.name !== "Catálogo de sociedades"
      ) {
        const Values = {
          usuario: id,
          operador: operador,
          dato: params.name,
        };
        const Peticion = new Request("api/home/RowDocuments", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": access,
          },
          method: "POST",
          body: JSON.stringify(Values),
        });

        try {
          const response = await fetch(Peticion);
          const data = await response.json();
          params.Registro = await data.data.Registro;
          setProduct([...product, { Registro: data.data.Registro }]);
        } catch (error) {
          console.log("Failed to fetch from login", error);
        }
      }
    };

    product.map((item) => fetchData(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!UpdateAccessToken()) {
    return <Navigate to="/" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <Header
          IsSession={false}
          open={false}
          setOpen={props.setOpen}
          operador={operador}
          usuario={usuario}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 10,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          {/* Sociedades */}

          <Grid container rowSpacing={1} justifyContent="center">
            <Grid item xs={12}>
              <Item>
                <TablaSociedadesDetalles sociedades={sociedades} />
              </Item>
            </Grid>
          </Grid>
          <br />
          {operador === "Empleado" && (
            <Grid container rowSpacing={0} justifyContent="center">
              <Typography variant="h5" justifyContent="center">
                Cátalogos
              </Typography>
            </Grid>
          )}
          {/* Esto es catalogos */}
          {operador === "Empleado" && (
            <Grid container rowSpacing={1} justifyContent="center">
              <Grid item xs={1.5} id="btnCatEmp">
                <Button key={1} onClick={OnButtonClick} id="btnCatEmp">
                  <Card
                    sx={{
                      maxWidth: 148,
                      maxHeight: 200,
                      width: 148,
                      height: 140,
                      border: 3,
                      borderRadius: "20px",
                      borderColor: "#b6d5ff",
                    }}
                    id="btnCatEmp"
                  >
                    <img
                      src={ImgCatEmp}
                      id="btnCatEmp"
                      alt="CatEmp"
                      width={"50px"}
                    />
                    <CardContent id="btnCatEmp">
                      <Typography gutterBottom variant="h7" id="btnCatEmp">
                        Catálogo de empleados
                      </Typography>
                    </CardContent>
                  </Card>
                </Button>
              </Grid>
              <Grid item xs={1.5} id="btnCatPro">
                <Button key={1} onClick={OnButtonClick} id="btnCatPro">
                  <Card
                    sx={{
                      maxWidth: 148,
                      maxHeight: 200,
                      width: 148,
                      height: 140,
                      border: 3,
                      borderRadius: "20px",
                      borderColor: "#b6d5ff",
                    }}
                    id="btnCatPro"
                  >
                    <img
                      src={ImgCatProv}
                      id="btnCatPro"
                      alt="CatPro"
                      width={"50px"}
                    />
                    <CardContent id="btnCatPro">
                      <Typography
                        gutterBottom
                        variant="h7"
                        component="div"
                        id="btnCatPro"
                      >
                        Catálogo de proveedores
                      </Typography>
                    </CardContent>
                  </Card>
                </Button>
              </Grid>
              <Grid item xs={1.5} id="btnCatSoc">
                <Button key={1} onClick={OnButtonClick} id="btnCatSoc">
                  <Card
                    sx={{
                      maxWidth: 148,
                      maxHeight: 200,
                      width: 148,
                      height: 140,
                      border: 3,
                      borderRadius: "20px",
                      borderColor: "#b6d5ff",
                    }}
                    id="btnCatSoc"
                  >
                    <img
                      src={ImgCatSoc}
                      id="btnCatSoc"
                      alt="CatPro"
                      width={"50px"}
                    />
                    <CardContent id="btnCatSoc">
                      <Typography
                        gutterBottom
                        variant="h7"
                        component="div"
                        id="btnCatSoc"
                      >
                        Catálogo de sociedades
                      </Typography>
                    </CardContent>
                  </Card>
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid container rowSpacing={0} justifyContent="center">
            <Typography variant="h5" justifyContent="center">
              Pedidos y entradas
            </Typography>
          </Grid>
          {/* Esto es pedidos y entradas */}
          <Grid container rowSpacing={1} justifyContent="center">
            <Grid item xs={1.5} id="btnPedi">
              <Button key={1} onClick={OnButtonClick} id="btnPedi">
                <Card
                  sx={{
                    width: 148,
                    height: 160,
                    border: 3,
                    borderRadius: "20px",
                    borderColor: "#b6d5ff",
                  }}
                  id="btnPedi"
                >
                  <img src={ImgPedi} id="btnPedi" alt="CatPro" width={"50px"} />
                  <CardContent
                    sx={{
                      height: 55,
                    }}
                    id="btnPedi"
                  >
                    <Typography gutterBottom variant="h7" id="btnPedi">
                      Pedidos
                    </Typography>
                  </CardContent>
                  <CardContent d="btnPedi">
                    <Typography variant="h6" id="btnPedi">
                      {product.map(
                        (item) => item.idName === "btnPedi" && item.Registro
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Button>
            </Grid>
            <Grid item xs={1.5} id="btnEntMer">
              <Button key={1} onClick={OnButtonClick} id="btnEntMer">
                <Card
                  sx={{
                    width: 148,
                    height: 160,
                    border: 3,
                    borderRadius: "20px",
                    borderColor: "#b6d5ff",
                  }}
                  id="btnEntMer"
                >
                  <img
                    src={ImgEntradas}
                    id="btnEntMer"
                    alt="CatPro"
                    width={"50px"}
                  />
                  <CardContent
                    sx={{
                      height: 55,
                    }}
                    id="btnEntMer"
                  >
                    <Typography gutterBottom variant="h7" id="btnEntMer">
                      Entradas de mercancia
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h6" id="btnEntMer">
                      {product.map(
                        (item) => item.idName === "btnEntMer" && item.Registro
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Button>
            </Grid>
          </Grid>
          <Grid container rowSpacing={0} justifyContent="center">
            <Typography variant="h5" justifyContent="center">
              Documentos Electronicos
            </Typography>
          </Grid>
          {/* Esto son los documentos */}
          <Grid container rowSpacing={1} justifyContent="center">
            {operador === "Empleado" && validacion === "Y" && (
              <Grid item xs={1.5} id="btnFacPen">
                <Button key={1} onClick={OnButtonClick} id="btnFacPen">
                  <Card
                    sx={{
                      width: 148,
                      height: 160,
                      border: 3,
                      borderRadius: "20px",
                      borderColor: "#b6d5ff",
                    }}
                    id="btnFacPen"
                  >
                    <img
                      src={ImgFacPen}
                      id="btnFacPen"
                      alt="CatPro"
                      width={"58px"}
                    />
                    <CardContent
                      sx={{
                        height: 55,
                      }}
                      id="btnFacPen"
                    >
                      <Typography gutterBottom variant="h7" id="btnFacPen">
                        Facturas pendientes
                      </Typography>
                    </CardContent>
                    <CardContent id="btnFacPen">
                      <Typography variant="h6" id="btnFacPen">
                        {product.map(
                          (item) => item.idName === "btnFacPen" && item.Registro
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Button>
              </Grid>
            )}
            <Grid item xs={1.5} id="btnMisFac">
              <Button key={1} onClick={OnButtonClick} id="btnMisFac">
                <Card
                  sx={{
                    width: 148,
                    height: 160,
                    border: 3,
                    borderRadius: "20px",
                    borderColor: "#b6d5ff",
                  }}
                  id="btnMisFac"
                >
                  <img
                    src={ImgMisFac}
                    id="btnMisFac"
                    alt="CatPro"
                    width={"50px"}
                  />
                  <CardContent
                    sx={{
                      height: 55,
                    }}
                    id="btnMisFac"
                  >
                    <Typography gutterBottom variant="h7" id="btnMisFac">
                      Facturas
                    </Typography>
                  </CardContent>
                  <CardContent id="btnMisFac">
                    <Typography variant="h6" id="btnMisFac">
                      {product.map(
                        (item) => item.idName === "btnMisFac" && item.Registro
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Button>
            </Grid>
            {operador === "Empleado" && validacion === "Y" && (
              <Grid item xs={1.5} id="btnCoPaPe">
                <Button key={1} onClick={OnButtonClick} id="btnCoPaPe">
                  <Card
                    sx={{
                      width: 148,
                      height: 160,
                      border: 3,
                      borderRadius: "20px",
                      borderColor: "#b6d5ff",
                    }}
                    id="btnCoPaPe"
                  >
                    <img
                      src={ImgPagoPendiente}
                      id="btnCoPaPe"
                      alt="CatPro"
                      width={"50px"}
                    />
                    <CardContent
                      sx={{
                        height: 55,
                      }}
                      id="btnCoPaPe"
                    >
                      <Typography gutterBottom variant="h7" id="btnCoPaPe">
                        Pagos pendientes
                      </Typography>
                    </CardContent>
                    <CardContent id="btnCoPaPe">
                      <Typography variant="h6" id="btnCoPaPe">
                        {product.map(
                          (item) => item.idName === "btnCoPaPe" && item.Registro
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Button>
              </Grid>
            )}
            <Grid item xs={1.5} id="btnConPag">
              <Button key={1} onClick={OnButtonClick} id="btnConPag">
                <Card
                  sx={{
                    width: 148,
                    height: 160,
                    border: 3,
                    borderRadius: "20px",
                    borderColor: "#b6d5ff",
                  }}
                  id="btnConPag"
                >
                  <img
                    src={ImgPago}
                    id="btnConPag"
                    alt="CatPro"
                    width={"50px"}
                  />
                  <CardContent
                    sx={{
                      height: 55,
                    }}
                    id="btnConPag"
                  >
                    <Typography gutterBottom variant="h7" id="btnConPag">
                      Pagos
                    </Typography>
                  </CardContent>
                  <CardContent id="btnConPag">
                    <Typography variant="h6" id="btnConPag">
                      {product.map(
                        (item) => item.idName === "btnConPag" && item.Registro
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

Home.propTypes = {
  usuario: PropTypes.string,
  open: PropTypes.bool,
};

export default Home;
