import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  TableContainer,
  Table,
  Paper,
  Tooltip,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  TableSortLabel,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { visuallyHidden } from "@mui/utils";
import { StyledButtonEditar } from "./Styled-Pagos";
import { CeldasPagos } from "../Data/Pagos-Data";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { obtenerFormaDePago, FormatoMoneda } from "../Data/DatosSAT";

//Funcion para filtrar por la busqueda
function Ordenamiento(array, comparator, Filter) {
  const rows = [];
  array.forEach((Pago) => {
    if (
      Pago.id !== undefined &&
      Pago.id !== "" &&
      Pago.id !== null &&
      Pago.id.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.Sociedad !== undefined &&
      Pago.Sociedad !== "" &&
      Pago.Sociedad !== null &&
      Pago.Sociedad.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.NomProveedor !== undefined &&
      Pago.NomProveedor !== "" &&
      Pago.NomProveedor !== null &&
      Pago.NomProveedor.toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.UUID !== undefined &&
      Pago.UUID !== "" &&
      Pago.UUID !== null &&
      Pago.UUID.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.PagoSAP !== undefined &&
      Pago.PagoSAP !== "" &&
      Pago.PagoSAP !== null &&
      Pago.PagoSAP.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.UUID !== undefined &&
      Pago.UUID !== "" &&
      Pago.UUID !== null &&
      Pago.UUID.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.Fecha !== undefined &&
      Pago.Fecha !== "" &&
      Pago.Fecha !== null &&
      Pago.Fecha.toString().toLowerCase().indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.FechaPago !== undefined &&
      Pago.FechaPago !== "" &&
      Pago.FechaPago !== null &&
      Pago.FechaPago.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.TotalPago !== undefined &&
      Pago.TotalPago !== "" &&
      Pago.TotalPago !== null &&
      Pago.TotalPago.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.FechaComplemento !== undefined &&
      Pago.FechaComplemento !== "" &&
      Pago.FechaComplemento !== null &&
      Pago.FechaComplemento.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.EstatusComplemento !== undefined &&
      Pago.EstatusComplemento !== "" &&
      Pago.EstatusComplemento !== null &&
      Pago.EstatusComplemento.toString()
        .toLowerCase()
        .indexOf(Filter.toLowerCase()) !== -1
    ) {
      rows.push(Pago);
    } else if (
      Pago.MetodoPago !== undefined &&
      Pago.MetodoPago !== "" &&
      Pago.MetodoPago !== null &&
      Pago.MetodoPago.toString().toLowerCase().indexOf(Filter.toLowerCase()) !==
        -1
    ) {
      rows.push(Pago);
    }
  });

  const stabilizedThis = rows.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparador(order, orderBy) {
  return order === "desc"
    ? (a, b) => ComparadorDecendiente(a, b, orderBy)
    : (a, b) => -ComparadorDecendiente(a, b, orderBy);
}

function ComparadorDecendiente(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function EncabezadoDatos(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {CeldasPagos.map((headCell) => (
          <TableCell
            typeof="Password"
            key={headCell.id}
            size="small"
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row } = props;
  const navigate = useNavigate();

  const [formaPago, setFormaPago] = React.useState("");

  const handleClick = (event, id) => {
    navigate("/Pagos/Pago/" + id);
  };
  const handleChangeFormaPago = (event, id) => {
    setFormaPago(obtenerFormaDePago(id));
  };

  const [open, setOpen] = React.useState(false);

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const createFile = (event, b64Data) => {
    let fileURL;
    switch (event.target.id) {
      case "PDF":
      case "Complemento":
        const blobpdf = b64toBlob(b64Data, "application/pdf");
        fileURL = URL.createObjectURL(blobpdf);
        break;
      case "XML":
        const blobxml = b64toBlob(b64Data, "text/xml");
        fileURL = URL.createObjectURL(blobxml);
        break;
      case "Recepcion":
        const blobrecepcion = b64toBlob(
          b64Data,
          "image/gif, image/jpeg, image/png"
        );
        fileURL = URL.createObjectURL(blobrecepcion);
        break;
      default:
      // code block
    }

    window.open(fileURL);
  };
  return (
    <React.Fragment>
      <TableRow
        hover
        onDoubleClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={row.id}
        tabIndex={-1}
        key={row.id}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell
          component="th"
          id={props.row.key}
          key={props.row.key}
          scope="row"
          padding="normal"
        >
          {row.id}
        </TableCell>

        <TableCell padding="checkbox" align="center">
          {row.Sociedad}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          {row.NomProveedor}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          {row.UUID}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          {row.PagoSAP}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          {row.Fecha}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          {row.FechaPago}
        </TableCell>
        <TableCell
          padding="checkbox"
          align="center"
          onMouseOver={(event) => handleChangeFormaPago(event, row.MetodoPago)}
        >
          <Tooltip title={formaPago}>{row.MetodoPago}</Tooltip>
        </TableCell>
        <TableCell padding="checkbox" align="center">
          {row.TotalPago + " " + row.Moneda}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          {row.FechaComplemento}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          {row.EstatusComplemento}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          {row.Complemento !== "" && (
            <StyledButtonEditar
              size="medium"
              component="label"
              role={undefined}
              tabIndex={-1}
              endIcon={<PictureAsPdfIcon />}
              id="Complemento"
              name="Complemento"
              onClick={(e) => createFile(e, row.Complemento)}
            >
              Visualizar
            </StyledButtonEditar>
          )}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          {row.PDF !== "" && (
            <StyledButtonEditar
              size="medium"
              component="label"
              role={undefined}
              tabIndex={-1}
              endIcon={<PictureAsPdfIcon />}
              id="PDF"
              name="PDF"
              onClick={(e) => createFile(e, row.PDF)}
            >
              Visualizar
            </StyledButtonEditar>
          )}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          {row.XML !== "" && (
            <StyledButtonEditar
              size="medium"
              component="label"
              role={undefined}
              tabIndex={-1}
              endIcon={<PictureAsPdfIcon />}
              id="XML"
              name="XML"
              onClick={(e) => createFile(e, row.XML)}
            >
              Visualizar
            </StyledButtonEditar>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Factura(s)
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Folio SAP</TableCell>
                    <TableCell>UUID</TableCell>
                    <TableCell>Código Factura</TableCell>
                    <TableCell align="right">Fecha</TableCell>
                    <TableCell align="right">Monto Aplicado</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.Detalle !== undefined &&
                    row.Detalle.map((historyRow) => (
                      <TableRow key={historyRow.UUID}>
                        <TableCell component="th" scope="row">
                          {historyRow.FolioSAP}
                        </TableCell>
                        <TableCell>{historyRow.UUID}</TableCell>
                        <TableCell>{historyRow.FolioFactura}</TableCell>
                        <TableCell align="right">
                          {historyRow.FechaFactura}
                        </TableCell>
                        <TableCell align="right">
                          {FormatoMoneda(historyRow.Total) +
                            " " +
                            historyRow.Moneda}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function PagosTabla(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("#");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      Ordenamiento(
        props.rows,
        getComparador(order, orderBy),
        props.filtro
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, props.filtro, props.rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table>
            <EncabezadoDatos
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
            />
            <TableBody>
              {visibleRows.map((row) => (
                <Row key={row.name} row={row} />
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                ></TableRow>
              )}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                ></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            15,
            { value: props.rows.length, label: "All" },
          ]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

PagosTabla.propTypes = {
  row: PropTypes.object,
};

export default PagosTabla;
