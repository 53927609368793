import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { Header, MenuLateral } from "../Header/Header";
import {
  Box,
  Toolbar,
  Typography,
  Select,
  Grid,
  Snackbar,
  FormControl,
  MenuItem,
  TextField,
  OutlinedInput,
  InputAdornment,
  Alert,
  Checkbox,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { isAuthenticatedvalid } from "../Router/Cookies";
import { ThemeProvider } from "@mui/material/styles";
import {
  theme,
  StyledButton,
  StyledDiv,
  StyledTheme,
  StylePaperPrincipal,
  StylePaperButton,
} from "./CtaEmpleados-css";
import Cookies from "js-cookie";
import { UpdateAccessToken } from "../Router/Cookies";

function Empleado(props) {
  let params = useParams();

  let IsSession = isAuthenticatedvalid("access");
  const navigate = useNavigate();
  const [values, setValues] = React.useState({
    Id: "",
    Usuario: Cookies.get("id"),
    RolEmpleado: "",
    Nombre: "",
    Apellidos: "",
    Correo: "",
    Area: "",
    Estatus: "I",
    Contraseña: "",
    ViDocPen: "Y",
  });
  const [habilitar, setHabilitar] = React.useState(false);
  const [Generacion, setGeneracion] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [habilarBoton, setHabilarBoton] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMensaje, setErrorMensaje] = React.useState("");
  const [areas, setAreas] = React.useState(null);
  const [crear, setCrear] = React.useState("Crear");

  UpdateAccessToken();

  const { tipoPerfil, empleados } = props;
  const operador = Cookies.get("operador");
  const tipoEmpleado = Cookies.get("tipoEmpleado");
  const access = Cookies.get("access");

  const getAreas = async (setAreas) => {
    const Peticion = new Request("/api/empleados/getAreas", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "GET",
    });

    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      setAreas(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowPassword = (tipoEmpleadoRegistro) => {
    if (tipoEmpleado === "Administrador") {
      setShowPassword((show) => !show);
    } else {
      if (tipoPerfil !== undefined) {
        setShowPassword((show) => !show);
      }
    }
  };

  React.useEffect(() => {
    getAreas(setAreas);
    let iRegistro;

    // Validamos que si viene de perfil o de catalgos para poder crear o actulizar

    //Cuando es por parte del catalogo de empleados
    if (tipoPerfil === undefined) {
      if (params.id !== "Nuevo") {
        if (empleados === undefined || empleados.length === 0) {
          navigate("/Catalogo-Empleados");
        } else {
          iRegistro = params.id - 1;
        }
      }
    } else {
      // cuando es por parte del pefil
      if (tipoPerfil) {
        if (empleados === undefined || empleados.length === 0) {
          navigate("/Home");
        } else {
          iRegistro = 0;
        }
      }
    }

    if (iRegistro !== undefined) {
      let Estatus;

      if (empleados[iRegistro].Estatus === "Activo") {
        Estatus = "A";
      } else {
        Estatus = "I";
      }

      setValues({
        Id: empleados[iRegistro].IdEmpleado,
        Usuario: empleados[iRegistro].Usuario,
        RolEmpleado: empleados[iRegistro].rolEmpleado,
        Contraseña: empleados[iRegistro].Contraseña,
        Nombre: empleados[iRegistro].Nombre,
        Apellidos: empleados[iRegistro].Apellidos,
        Correo: empleados[iRegistro].Correo,
        Area: empleados[iRegistro].IdArea,
        Estatus: Estatus,
        ViDocPen: empleados[iRegistro].ViDocPen,
      });

      if (operador === "Empleado" && tipoEmpleado === "Administrador") {
        setHabilitar(false);
      } else {
        setHabilitar(true);
        setHabilarBoton(true);
      }

      setCrear("Actualizar");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empleados]);

  async function handleSubmit(e) {
    e.preventDefault();
    let url;
    if (crear === "Crear") {
      url = "/api/empleados/createEmpleado";
    } else {
      url = "/api/empleados/updateEmpleado";
    }

    const Peticion = new Request(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(values),
    });

    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      if (data.status === "success") {
        setHabilarBoton(true);
        setGeneracion(true);
        setOpenMessage(true);
        setError(false);
      } else {
        setError(true);
        setOpenMessage(true);
        setErrorMensaje(data.message);
      }
    } catch (error) {
      console.error("Failed to fetch from updateSociedad", error);
    }
  }

  function handleChange(evt) {
    const { target } = evt;
    const { name, value } = target;
    console.log(name);
    console.log(value);
    let sValue = "N";
    let newValues = {};
    if (name === "ViDocPen") {
      if (evt.target.checked === false) {
        sValue = "N";
      } else if (evt.target.checked === true) {
        sValue = "Y";
      }

      newValues = {
        ...values,
        [name]: sValue,
      };
    } else {
      newValues = {
        ...values,
        [name]: value,
      };
    }

    setValues(newValues);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);

    if (Generacion === true) {
      navigate("/Catalogo-Empleados");
    }
  };

  if (IsSession) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Header
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
            operador={Cookies.get("operador")}
            usuario={Cookies.get("usuario")}
          />
          <MenuLateral
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 10,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Typography
              sx={{ my: 3, mx: 2 }}
              align="center"
              variant="h5"
              component="h5"
            >
              Datos generales
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperPrincipal variant="outlined">
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *ID Usuario:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-Empleado-Id"
                      size="small"
                      variant="outlined"
                      required
                      id="Id"
                      name="Id"
                      autoFocus
                      value={values.Id}
                      disabled={crear === "Actualizar" ? true : false}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Rol empleado:
                    </Typography>
                    <Select
                      fullWidth
                      align="left"
                      autoComplete="section-Empleado-RolEmpleado"
                      size="small"
                      required
                      id="RolEmpleado"
                      name="RolEmpleado"
                      value={values.RolEmpleado}
                      onChange={handleChange}
                      disabled={habilitar}
                    >
                      <MenuItem id="rolEmpleado-A" value="A">
                        Administrador
                      </MenuItem>
                      <MenuItem id="rolEmpleado-G" value="G">
                        general
                      </MenuItem>
                    </Select>
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Nombre:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-Empleado-Nombre"
                      size="small"
                      variant="outlined"
                      required
                      id="Nombre"
                      name="Nombre"
                      value={values.Nombre}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Apellidos:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-Empleado-Apellidos"
                      size="small"
                      variant="outlined"
                      required
                      id="Apellidos"
                      name="Apellidos"
                      value={values.Apellidos}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Correo:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-Empleado-Correo"
                      size="small"
                      variant="outlined"
                      required
                      id="Correo"
                      name="Correo"
                      value={values.Correo}
                      disabled={habilitar}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Área:
                    </Typography>

                    <Select
                      fullWidth
                      align="left"
                      autoComplete="section-Empleado-Area"
                      size="small"
                      required
                      id="Area"
                      name="Area"
                      value={values.Area}
                      onChange={handleChange}
                      disabled={habilitar}
                    >
                      {areas !== null &&
                        areas.map((area) => {
                          return (
                            <MenuItem
                              key={area.IdArea}
                              id={area.IdArea}
                              value={area.IdArea}
                            >
                              {area.Nombre}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Estatus:
                    </Typography>
                    <Select
                      autoComplete="section-Empleado-Estatus"
                      size="small"
                      fullWidth
                      align="left"
                      variant="outlined"
                      required
                      id="Estatus"
                      name="Estatus"
                      value={values.Estatus}
                      onChange={handleChange}
                      disabled={params.id === "Nuevo" ? false : true}
                    >
                      <MenuItem id="Estatus-A" value="A">
                        Activo
                      </MenuItem>
                      <MenuItem id="Estatus-I" value="I">
                        Inactivo
                      </MenuItem>
                    </Select>
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Contraseña:
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        autoComplete="section-Empleado-Contraseña"
                        size="small"
                        fullWidth
                        variant="outlined"
                        required
                        id="Contraseña"
                        name="Contraseña"
                        value={values.Contraseña}
                        onChange={handleChange}
                        type={showPassword ? "text" : "password"}
                        disabled={habilitar}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword} edge="end">
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </StyledDiv>
                  {tipoPerfil === undefined &&
                    tipoEmpleado === "Administrador" && (
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Módulos de documentos pendientes:
                        </Typography>
                        <Checkbox
                          fullWidth
                          checked={values.ViDocPen === "Y" ? true : false}
                          align="left"
                          autoComplete="section-Empleado-ViDocPen"
                          size="small"
                          variant="outlined"
                          id="ViDocPen"
                          name="ViDocPen"
                          disabled={habilitar}
                          onChange={handleChange}
                        ></Checkbox>
                      </StyledDiv>
                    )}
                </StylePaperPrincipal>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperButton variant="elevation" elevation={0}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={StyledTheme.submit}
                    disabled={habilarBoton}
                  >
                    {crear}
                  </StyledButton>
                </StylePaperButton>
              </Grid>
            </form>
          </Box>
        </Box>
        {Generacion === true && (
          <Snackbar
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            open={openMessage}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Se {crear === "Actualizar" ? "actualizó" : "creó"} con éxito el
              empleado!
            </Alert>
          </Snackbar>
        )}
        {error === true && (
          <Snackbar
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            open={openMessage}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {errorMensaje}
            </Alert>
          </Snackbar>
        )}
      </ThemeProvider>
    );
  } else {
    return <Navigate to="/" />;
  }
}

Empleado.propTypes = {
  empleados: PropTypes.array,
};

export default Empleado;
