export function addPago(
  id,
  Numero,
  Sociedad,
  NomProveedor,
  UUID,
  Factura,
  PagoSAP,
  Fecha,
  FechaPago,
  MetodoPago,
  TotalPago,
  FechaComplemento,
  EstatusComplemento,
  Complemento,
  PDF,
  XML,
  BD,
  Moneda,
  Folio,
  RFCEmisor,
  RFCReceptor,
  CPEmisor,
  CPReceptor,
  Uso,
  Regimen,
  Correo,
  Detalle
) {
  return {
    id,
    Numero,
    Sociedad,
    NomProveedor,
    UUID,
    Factura,
    PagoSAP,
    Fecha,
    FechaPago,
    MetodoPago,
    TotalPago,
    FechaComplemento,
    EstatusComplemento,
    Complemento,
    PDF,
    XML,
    BD,
    Moneda,
    Folio,
    RFCEmisor,
    RFCReceptor,
    CPEmisor,
    CPReceptor,
    Uso,
    Regimen,
    Correo,
    Detalle,
  };
}

export function addDetallePago(
  FolioSAP,
  FolioFactura,
  UUID,
  FechaFactura,
  Total,
  Moneda
) {
  return {
    FolioSAP,
    FolioFactura,
    UUID,
    FechaFactura,
    Total,
    Moneda,
  };
}

export const CeldasPagos = [
  {
    id: "#",
    numeric: false,
    disablePadding: false,
    label: "#",
    minWidth: "2em",
  },
  {
    id: "Sociedad",
    numeric: false,
    disablePadding: false,
    label: "Sociedad",
    minWidth: "15em",
  },
  {
    id: "NomProveedor",
    numeric: false,
    disablePadding: false,
    label: "Nombre del proveedor",
    minWidth: "15em",
  },

  {
    id: "UUID",
    numeric: false,
    disablePadding: false,
    label: "UUID folio fiscal",
    minWidth: "21em",
  },

  {
    id: "Pago",
    numeric: false,
    disablePadding: false,
    label: "Pago",
    minWidth: "5em",
  },
  {
    id: "FechaComplemento",
    numeric: false,
    disablePadding: false,
    label: "Fecha complemento",
    minWidth: "14em",
  },
  {
    id: "FechaPago",
    numeric: false,
    disablePadding: false,
    label: "Fecha de pago",
    minWidth: "12em",
  },
  {
    id: "MetodoPago",
    numeric: false,
    disablePadding: false,
    label: "Forma de pago",
    minWidth: "12em",
  },
  {
    id: "TotalPago",
    numeric: true,
    disablePadding: false,
    label: "Total de pago",
    minWidth: "12em",
  },
  {
    id: "Fecha",
    numeric: false,
    disablePadding: false,
    label: "Fecha de timbrado",
    minWidth: "13em",
  },
  {
    id: "Estatus",
    numeric: false,
    disablePadding: false,
    label: "Estatus",
    minWidth: "8em",
  },
  {
    id: "Complemento",
    numeric: false,
    disablePadding: false,
    label: "Comprobante",
    minWidth: "8em",
  },
  {
    id: "PDF",
    numeric: false,
    disablePadding: false,
    label: "PDF",
    minWidth: "8em",
  },

  {
    id: "XML",
    numeric: false,
    disablePadding: false,
    label: "XML",
    minWidth: "8em",
  },
];
