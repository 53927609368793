import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { Header, MenuLateral } from "../Header/Header";
import {
  Paper,
  Box,
  Toolbar,
  Typography,
  Select,
  Grid,
  Snackbar,
  FormControl,
  MenuItem,
  TextField,
  OutlinedInput,
  InputAdornment,
  Alert,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { isAuthenticatedvalid } from "../Router/Cookies";
import { ThemeProvider } from "@mui/material/styles";
import {
  theme,
  StyledButton,
  StylePaperPrincipal,
  StyledDiv,
  StylePaperButton,
  StyledTheme,
  StyledButtonEditar,
  StyledInputFile,
} from "./CtaProveddores-css";
import Cookies from "js-cookie";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import TablaSociedadesDetalles from "./Sociedades/TablaSociedadesDetalles";
import { convertToBase64 } from "../Data/ArchivoBase64";
import createFile from "../Data/ArchivoBase64";

function Proveedor(props) {
  let params = useParams();
  const operador = Cookies.get("operador");
  const tipoEmpleado = Cookies.get("tipoEmpleado");
  const access = Cookies.get("access");
  const [filtro, setFiltro] = React.useState("");
  const [habilitar, setHabilitar] = React.useState(false);
  const [Generacion, setGeneracion] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [habilitarBoton, setHabilitarBoton] = React.useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMensaje, setErrorMensaje] = React.useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const { tipoPerfil, proveedores } = props;
  let IsSession = isAuthenticatedvalid("access");

  const [values, setValues] = React.useState({
    usuario: operador,
    codigoSAP: "",
    tipoPersona: "",
    razonSocial: "",
    RFC: "",
    telefono: "",
    correo: "",
    persona: "",
    contraseña: "",
    estatusRegistro: "",
    motivo: "",
    estatus: "",

    ValActaConstitutiva: "",
    DiasActaConstitutiva: "",
    ActaConstitutiva: "",
    ActaConstitutiva64: "",
    FechaActaConstitutiva: "",

    ValIdentificacion: "",
    DiasIdentificacion: "",
    Identificacion: "",
    Identificacion64: "",
    FechaIdentificacion: "",

    ValComprobante: "",
    DiasComprobante: "",
    Comprobante: "",
    Comprobante64: "",
    FechaComprobante: "",

    ValCedula: "",
    DiasCedula: "",
    Cedula: "",
    Cedula64: "",
    FechaCedula: "",

    ValEdoCuenta: "",
    DiasEdoCuenta: "",
    EdoCuenta: "",
    EdoCuenta64: "",
    FechaEdoCuenta: "",

    ValObligacionFiscal: "",
    DiasObligacionFiscal: "",
    ObligacionFiscal: "",
    bligacionFiscal64s: "",
    FechaObligacionFiscal: "",

    DocAdicional: "",
    DocAdicional64: "",
    FechaDocAdicional: "",
    Sociedades: [],
  });

  React.useEffect(() => {
    let iRegistro;
    let EstatusRegistro;
    let Estatus;

    // Validamos que si viene de perfil o de catalgos para poder crear o actulizar

    //Cuando es por parte del catalogo de empleados
    if (tipoPerfil === undefined) {
      if (params.id !== "Nuevo") {
        if (proveedores === undefined || proveedores.length === 0) {
          navigate("/Catalogo-Proveedores");
        } else {
          iRegistro = params.id - 1;
        }
      }
    } else {
      // cuando es por parte del pefil
      if (tipoPerfil) {
        if (proveedores === undefined || proveedores.length === 0) {
          navigate("/Home");
        } else {
          iRegistro = 0;
        }
      }
    }

    if (iRegistro !== undefined) {
      if (proveedores[iRegistro].EstatusRegistro === "Completo") {
        EstatusRegistro = "C";
      } else if (proveedores[iRegistro].EstatusRegistro === "Rechazado") {
        EstatusRegistro = "R";
      } else {
        EstatusRegistro = "P";
      }

      if (proveedores[iRegistro].Estatus === "Activo") {
        Estatus = "A";
      } else {
        Estatus = "I";
      }
      setValues({
        operador: operador,
        codigoSAP: proveedores[iRegistro].CodigoSAP,
        tipoPersona: proveedores[iRegistro].TipoPersona,
        razonSocial: proveedores[iRegistro].RazonSocial,
        RFC: proveedores[iRegistro].RFC,
        telefono: proveedores[iRegistro].Telefono,
        correo: proveedores[iRegistro].Correo,
        personaContacto: proveedores[iRegistro].PersonaContacto,
        contraseña: proveedores[iRegistro].Contraseña,
        estatusRegistro: EstatusRegistro,
        motivo: proveedores[iRegistro].Motivo,
        estatus: Estatus,

        ValActaConstitutiva: proveedores[iRegistro].ValActaConstitutiva,
        DiasActaConstitutiva: proveedores[iRegistro].DiasActaConstitutiva,
        FechaActaConstitutiva: proveedores[iRegistro].FechaActaConstitutiva,
        ActaConstitutiva64: proveedores[iRegistro].ActaConstitutiva,
        ActaConstitutiva: proveedores[iRegistro].ActaConstitutiva
          ? "Cargado"
          : "No cargado",

        ValIdentificacion: proveedores[iRegistro].ValIdentificacion,
        DiasIdentificacion: proveedores[iRegistro].DiasIdentificacion,
        FechaIdentificacion: proveedores[iRegistro].FechaIdentificacion,
        Identificacion64: proveedores[iRegistro].Identificacion,
        Identificacion: proveedores[iRegistro].Identificacion
          ? "Cargado"
          : "No cargado",

        ValComprobante: proveedores[iRegistro].ValComprobante,
        DiasComprobante: proveedores[iRegistro].DiasComprobante,
        FechaComprobante: proveedores[iRegistro].FechaComprobante,
        Comprobante64: proveedores[iRegistro].Comprobante,
        Comprobante: proveedores[iRegistro].Comprobante
          ? "Cargado"
          : "No cargado",

        ValCedula: proveedores[iRegistro].ValCedula,
        DiasCedula: proveedores[iRegistro].DiasCedula,
        FechaCedula: proveedores[iRegistro].FechaCedula,
        Cedula64: proveedores[iRegistro].Cedula,
        Cedula: proveedores[iRegistro].Cedula ? "Cargado" : "No cargado",

        ValEdoCuenta: proveedores[iRegistro].ValEdoCuenta,
        DiasEdoCuenta: proveedores[iRegistro].DiasEdoCuenta,
        FechaEdoCuenta: proveedores[iRegistro].FechaEdoCuenta,
        EdoCuenta64: proveedores[iRegistro].EdoCuenta,
        EdoCuenta: proveedores[iRegistro].EdoCuenta ? "Cargado" : "No cargado",

        ValObligacionFiscal: proveedores[iRegistro].ValObligacionFiscal,
        DiasObligacionFiscal: proveedores[iRegistro].DiasObligacionFiscal,
        FechaObligacionFiscal: proveedores[iRegistro].FechaObligacionFiscal,
        ObligacionFiscal64: proveedores[iRegistro].ObligacionFiscal,
        ObligacionFiscal: proveedores[iRegistro].ObligacionFiscal
          ? "Cargado"
          : "No cargado",

        FechaDocAdicional: proveedores[iRegistro].FechaDocAdicional,
        DocAdicional64: proveedores[iRegistro].DocAdicional,
        DocAdicional: proveedores[iRegistro].DocAdicional
          ? "Cargado"
          : "No cargado",

        Sociedades: proveedores[iRegistro].Sociedades,
      });

      //Habilitar el boton de actulizar cuando sea proveedor

      if (operador === "Empleado" && tipoEmpleado === "Administrador") {
        setHabilitar(false);
      } else {
        if (operador === "Proveedor") {
          setHabilitar(true);
          setPassword(false);
        } else {
          setHabilitar(true);
          setPassword(true);
          setHabilitarBoton(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proveedores]);

  const handleForma = (event) => {
    event.preventDefault();
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (tipoPerfil) {
      if (values.tipoPersona === "Moral") {
        console.log(values.ValEdoCuenta);
        console.log(values.DiasEdoCuenta);
        console.log(values.EdoCuenta);
        if (
          values.ValActaConstitutiva === "Y" &&
          values.ActaConstitutiva === "No cargado" //||
          //   values.DiasActaConstitutiva === 0)
        ) {
          setErrorMensaje("Debe cargarse la acta constitutiva");
          setError(true);
          setOpenMessage(true);
          return;
        }

        if (
          values.ValEdoCuenta === "Y" &&
          values.DiasEdoCuenta === 0 //|| values.EdoCuenta === "No cargado")
        ) {
          setErrorMensaje("Debe cargarse el estado de cuenta");
          setError(true);
          setOpenMessage(true);
          return;
        }
      }

      if (
        values.ValIdentificacion === "Y" &&
        values.Identificacion === "No cargado" // ||
        //values.DiasIdentificacion === 0)
      ) {
        setErrorMensaje(
          "Debe cargarse la identificación oficial del representante"
        );
        setError(true);
        setOpenMessage(true);
        return;
      }

      if (
        values.ValComprobante === "Y" &&
        values.Comprobante === "No cargado" // || values.DiasComprobante === 0)
      ) {
        setErrorMensaje("Debe cargarse el comprobante de domicilio");
        setError(true);
        setOpenMessage(true);
        return;
      }

      if (
        values.ValCedula === "Y" &&
        values.Cedula === "No cargado" //|| values.DiasCedula === 0)
      ) {
        setErrorMensaje("Debe cargarse la cedula de identificación fiscal");
        setError(true);
        setOpenMessage(true);
        return;
      }

      if (
        values.ValObligacionFiscal === "Y" &&
        values.ObligacionFiscal === "No cargado" //||
        //values.DiasObligacionFiscal === 0)
      ) {
        setErrorMensaje(
          "Debe cargarse la opinión de cumplimiento de obligación fiscal"
        );
        setError(true);
        setOpenMessage(true);
        return;
      }
    }

    if (values.tipoPersona === "Fisica") {
      values.tipoPersona = "F";
    } else if (values.tipoPersona === "Moral") {
      values.tipoPersona = "M";
    } else {
      values.tipoPersona = "";
    }

    const Peticion = new Request("/api/proveedores/updateProveedor", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(values),
    });

    let EstatusRegistro, Estatus;
    if (values.estatusRegistro === "C") {
      EstatusRegistro = "Completo";
    } else if (values.estatusRegistro === "R") {
      EstatusRegistro = "Rechazado";
    } else {
      EstatusRegistro = "Pendiente";
    }

    if (values.estatus === "A") {
      Estatus = "Activo";
    } else {
      Estatus = "Inactivo";
    }

    if (values.tipoPersona === "F") {
      values.tipoPersona = "Fisica";
    } else if (values.tipoPersona === "M") {
      values.tipoPersona = "Moral";
    } else {
      values.tipoPersona = "";
    }

    const PeticionCorreo = new Request("/api/proveedores/sentCorreo", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify({
        Correo: values.correo,
        Tipo: "Proveedor",
        Usuario: values.razonSocial,
        EstatusRegistro: EstatusRegistro,
        Estatus: Estatus,
        Observaciones: values.motivo,
      }),
    });

    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      if (data.status === "success") {
        if (operador === "Empleado") {
          try {
            const response = await fetch(PeticionCorreo);
            const data = await response.json();
            if (data.status === "success") {
              setHabilitarBoton(true);
              setGeneracion(true);
              setOpenMessage(true);
              setError(false);
            } else {
              setError(true);
              setOpenMessage(true);
              setErrorMensaje(data.message);
            }
          } catch (error) {
            console.error("Failed to fetch from envio correo", error);
          }
        } else {
          setHabilitarBoton(true);
          setGeneracion(true);
          setOpenMessage(true);
          setError(false);
        }
      } else {
        setError(true);
        setOpenMessage(true);
        setErrorMensaje(data.message);
      }
    } catch (error) {
      console.error("Failed to fetch from updateSociedad", error);
    }
  }

  function handleChange(evt) {
    const { target } = evt;
    const { name, value } = target;
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessage(false);
    if (Generacion === true) {
      if (tipoPerfil === undefined) {
        navigate("/Catalogo-Proveedores");
      } else {
        navigate("/Home");
      }
    }
  };

  if (IsSession) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Header
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
            operador={Cookies.get("operador")}
            usuario={Cookies.get("usuario")}
          />
          <MenuLateral
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 10,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <form onSubmit={handleSubmit}>
              <Typography
                sx={{ my: 3, mx: 10 }}
                align="center"
                variant="h5"
                component="h5"
              >
                Datos generales
              </Typography>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperPrincipal variant="outlined">
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Código SAP:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-Proveedor-CodigoSAP"
                      size="small"
                      variant="outlined"
                      required
                      id="codigoSAP"
                      name="codigoSAP"
                      autoFocus
                      value={values.codigoSAP}
                      disabled={true}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Tipo Persona:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-Proveedor-tipoPersona"
                      size="small"
                      variant="outlined"
                      required
                      id="tipoPersona"
                      name="coditipoPersonagoSAP"
                      value={values.tipoPersona}
                      disabled={true}
                      onChange={handleChange}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Nombre de la razón social:
                    </Typography>
                    <TextField
                      autoComplete="section-Proveedor-razonSocial"
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      id="razonSocial"
                      name="razonSocial"
                      value={values.razonSocial}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *RFC:
                    </Typography>
                    <TextField
                      autoComplete="section-Proveedor-RFC"
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      id="RFC"
                      name="RFC"
                      value={values.RFC}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </StyledDiv>

                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Teléfono:
                    </Typography>
                    <TextField
                      autoComplete="section-proveedor-Telefono"
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      id="telefono"
                      name="telefono"
                      value={values.telefono}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Correo:
                    </Typography>
                    <TextField
                      autoComplete="section-proveedor-correo"
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      id="correo"
                      name="correo"
                      value={values.correo}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Persona de contacto:
                    </Typography>
                    <TextField
                      autoComplete="section-Proveedor-personaContacto"
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      id="personaContacto"
                      name="personaContacto"
                      value={values.personaContacto}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </StyledDiv>

                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Contraseña:
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                      <OutlinedInput
                        autoComplete="section-Proveedor-password"
                        size="small"
                        fullWidth
                        variant="outlined"
                        required
                        id="contraseña"
                        name="contraseña"
                        value={values.contraseña}
                        onChange={handleChange}
                        type={showPassword ? "text" : "password"}
                        disabled={password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </StyledDiv>

                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Estatus de registro:
                    </Typography>
                    <Select
                      autoComplete="section-Proveedor-EstatusRegistro"
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      id="estatusRegistro"
                      name="estatusRegistro"
                      value={values.estatusRegistro}
                      onChange={handleChange}
                      disabled={habilitar}
                    >
                      <MenuItem id="Completo" value="C">
                        Completo
                      </MenuItem>
                      <MenuItem id="Pendiente" value="P">
                        Pendiente
                      </MenuItem>
                      <MenuItem id="Rechazado" value="R">
                        Rechazado
                      </MenuItem>
                    </Select>
                  </StyledDiv>

                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      Motivo de rechazo:
                    </Typography>
                    <TextField
                      autoComplete="section-Proveedor-motivo"
                      size="small"
                      multiline
                      rows={3}
                      fullWidth
                      variant="outlined"
                      id="motivo"
                      name="motivo"
                      value={values.motivo}
                      onChange={handleChange}
                      disabled={habilitar}
                    />
                  </StyledDiv>

                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Estatus:
                    </Typography>
                    <Select
                      autoComplete="section-Proveedor-Estatus"
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      id="estatus"
                      name="estatus"
                      value={values.estatus}
                      onChange={handleChange}
                      disabled={true}
                    >
                      <MenuItem id="area-A" value="A">
                        Activo
                      </MenuItem>
                      <MenuItem id="area-I" value="I">
                        Inactivo
                      </MenuItem>
                    </Select>
                  </StyledDiv>
                </StylePaperPrincipal>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Archivos
                  setValues={setValues}
                  values={values}
                  habilitar={habilitar}
                  operador={operador}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperButton variant="elevation" elevation={0}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={StyledTheme.submit}
                    disabled={habilitarBoton}
                  >
                    Actualizar
                  </StyledButton>
                </StylePaperButton>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Paper
                  sx={{ maxWidth: "90%", margin: "auto", overflow: "hidden" }}
                >
                  <TablaSociedadesDetalles
                    sociedades={values.Sociedades}
                    filtro={filtro}
                    setFiltro={setFiltro}
                    handleForma={handleForma}
                    idProveedor={params.id}
                  />
                </Paper>
              </Grid>
            </form>
          </Box>
        </Box>
        {Generacion === true && (
          <Snackbar
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            open={openMessage}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Se actualizó con éxito el proveedor
            </Alert>
          </Snackbar>
        )}
        {error === true && (
          <Snackbar
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            open={openMessage}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {errorMensaje}
            </Alert>
          </Snackbar>
        )}
      </ThemeProvider>
    );
  } else {
    return <Navigate to="/" />;
  }
}

Proveedor.propTypes = {
  proveedores: PropTypes.array,
};

function Archivos(props) {
  const { values, setValues, operador } = props;

  const handleChange = async (evt) => {
    const { target } = evt;
    console.log(target);
    const { name, files, id } = target;
    console.log(files);
    var base64;

    if (files.length > 0) {
      try {
        const base64pdf = await convertToBase64(files[0]);
        var ArrayAuxiliar = [];
        ArrayAuxiliar = base64pdf.split(",");
        base64 = ArrayAuxiliar[1];
      } catch (error) {
        console.error("Error while converting to Base64:", error);
      }

      const newValues = {
        ...values,
        [name]: files[0].name,
        [id]: base64,
      };
      setValues(newValues);
    }
  };

  return (
    <>
      <Typography
        sx={{ my: 3, mx: 3 }}
        align="center"
        variant="h5"
        component="h5"
      >
        Carga de Documentos PDF
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <StylePaperPrincipal variant="outlined">
          <Grid>
            {/* Acta constitutiva  */}
            {values.tipoPersona === "Moral" && (
              <StyledDiv>
                <Typography
                  align="left"
                  variant="subtitle2"
                  gutterBottom
                  sx={window.innerWidth > 500 && { width: 1 / 2 }}
                >
                  {values.ValActaConstitutiva === "Y" ? "*" : ""}Acta
                  constitutiva:
                </Typography>
                <TextField
                  fullWidth
                  align="left"
                  autoComplete="section-Proveedor-ActaConstitutiva"
                  size="small"
                  variant="outlined"
                  required={values.ValActaConstitutiva === "Y" ? true : false}
                  id="ActaConstitutiva"
                  name="ActaConstitutiva"
                  value={values.ActaConstitutiva}
                  disabled={true}
                  helperText={
                    <>
                      <label>Restan {values.DiasActaConstitutiva} dias</label>
                      <sup>1</sup>
                    </>
                  }
                />
                {operador === "Proveedor" ? (
                  <StyledButtonEditar
                    size="medium"
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    endIcon={<CloudUploadIcon />}
                    id="ActaConstitutiva"
                    name="ActaConstitutiva"
                    onChange={handleChange}
                    multiple={false}
                  >
                    cargar
                    <StyledInputFile
                      id="ActaConstitutiva64"
                      name="ActaConstitutiva"
                      type="file"
                      accept="application/pdf"
                    />
                  </StyledButtonEditar>
                ) : (
                  values.ActaConstitutiva64 && (
                    <StyledButtonEditar
                      size="medium"
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      endIcon={<PictureAsPdfIcon />}
                      id="actaContitutiva"
                      name="actaContitutiva"
                      onClick={(e) =>
                        createFile(values.ActaConstitutiva64, "application/pdf")
                      }
                    >
                      Ver
                    </StyledButtonEditar>
                  )
                )}
              </StyledDiv>
            )}

            {/* Identificación oficial del representante  */}

            <StyledDiv>
              <Typography
                align="left"
                variant="subtitle2"
                gutterBottom
                sx={window.innerWidth > 500 && { width: 1 / 2 }}
              >
                {values.ValIdentificacion === "Y" ? "*" : ""}
                Identificación oficial del representante:
              </Typography>
              <TextField
                fullWidth
                align="left"
                autoComplete="section-Proveedor-Identificacion"
                size="small"
                variant="outlined"
                required
                id="Identificacion"
                name="Identificacion"
                value={values.Identificacion}
                disabled={true}
                helperText={
                  <>
                    <label>Restan {values.DiasIdentificacion} dias</label>
                    <sup>1</sup>
                  </>
                }
              />
              {operador === "Proveedor" ? (
                <StyledButtonEditar
                  size="medium"
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  endIcon={<CloudUploadIcon />}
                  id="identificacion"
                  name="identificacion"
                  onChange={handleChange}
                  multiple={false}
                >
                  cargar
                  <StyledInputFile
                    id="Identificacion64"
                    name="Identificacion"
                    type="file"
                    accept="application/pdf"
                  />
                </StyledButtonEditar>
              ) : (
                values.Identificacion64 && (
                  <StyledButtonEditar
                    size="medium"
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    endIcon={<PictureAsPdfIcon />}
                    id="Identificacion64"
                    name="Identificacion64"
                    onClick={(e) =>
                      createFile(values.Identificacion64, "application/pdf")
                    }
                  >
                    Ver
                  </StyledButtonEditar>
                )
              )}
            </StyledDiv>

            {/* Comprobante de domicilio  */}

            <StyledDiv>
              <Typography
                align="left"
                variant="subtitle2"
                gutterBottom
                sx={window.innerWidth > 500 && { width: 1 / 2 }}
              >
                {values.ValComprobante === "Y" ? "*" : ""}Comprobante de
                domicilio :
              </Typography>
              <TextField
                fullWidth
                align="left"
                autoComplete="section-Proveedor-Comprobante"
                size="small"
                variant="outlined"
                required
                id="Comprobante"
                name="Comprobante"
                value={values.Comprobante}
                disabled={true}
                helperText={
                  <>
                    <label>Restan {values.DiasComprobante} dias</label>
                    <sup>1</sup>
                  </>
                }
              />
              {operador === "Proveedor" ? (
                <StyledButtonEditar
                  size="medium"
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  endIcon={<CloudUploadIcon />}
                  id="Comprobante"
                  name="Comprobante"
                  onChange={handleChange}
                  multiple={false}
                >
                  cargar
                  <StyledInputFile
                    id="Comprobante64"
                    name="Comprobante"
                    type="file"
                    accept="application/pdf"
                  />
                </StyledButtonEditar>
              ) : (
                values.Comprobante64 && (
                  <StyledButtonEditar
                    size="medium"
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    endIcon={<PictureAsPdfIcon />}
                    id="Comprobante64"
                    name="Comprobante64"
                    onClick={(e) =>
                      createFile(values.Comprobante64, "application/pdf")
                    }
                  >
                    Ver
                  </StyledButtonEditar>
                )
              )}
            </StyledDiv>

            {/* Cedula de identificacion fiscal  */}

            <StyledDiv>
              <Typography
                align="left"
                variant="subtitle2"
                gutterBottom
                sx={window.innerWidth > 500 && { width: 1 / 2 }}
              >
                {values.ValCedula === "Y" ? "*" : ""}Cedula de identificación
                fiscal:
              </Typography>
              <TextField
                fullWidth
                align="left"
                autoComplete="section-Proveedor-Cedula"
                size="small"
                variant="outlined"
                required
                id="Cedula"
                name="Cedula"
                value={values.Cedula}
                disabled={true}
                helperText={
                  <>
                    <label>Restan {values.DiasCedula} dias</label>
                    <sup>1</sup>
                  </>
                }
              />
              {operador === "Proveedor" ? (
                <StyledButtonEditar
                  size="medium"
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  endIcon={<CloudUploadIcon />}
                  id="Cedula"
                  name="Cedula"
                  onChange={handleChange}
                  multiple={false}
                >
                  cargar
                  <StyledInputFile
                    id="Cedula64"
                    name="Cedula"
                    type="file"
                    accept="application/pdf"
                  />
                </StyledButtonEditar>
              ) : (
                values.Cedula64 && (
                  <StyledButtonEditar
                    size="medium"
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    endIcon={<PictureAsPdfIcon />}
                    id="Cedula64"
                    name="Cedula64"
                    onClick={(e) =>
                      createFile(values.Cedula64, "application/pdf")
                    }
                  >
                    Ver
                  </StyledButtonEditar>
                )
              )}
            </StyledDiv>

            {/* Estado de cuenta  */}

            {values.tipoPersona === "Moral" && (
              <StyledDiv>
                <Typography
                  align="left"
                  variant="subtitle2"
                  gutterBottom
                  sx={window.innerWidth > 500 && { width: 1 / 2 }}
                >
                  {values.ValEdoCuenta === "Y" ? "*" : ""}Estado de cuenta:
                </Typography>
                <TextField
                  fullWidth
                  align="left"
                  autoComplete="section-Proveedor-EdoCuenta"
                  size="small"
                  variant="outlined"
                  required
                  id="EdoCuenta"
                  name="EdoCuenta"
                  value={values.EdoCuenta}
                  disabled={true}
                  helperText={
                    <>
                      <label>Restan {values.DiasEdoCuenta} dias</label>
                      <sup>1</sup>
                    </>
                  }
                />
                {operador === "Proveedor" ? (
                  <StyledButtonEditar
                    size="medium"
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    endIcon={<CloudUploadIcon />}
                    id="EdoCuenta"
                    name="EdoCuenta"
                    onChange={handleChange}
                    multiple={false}
                  >
                    cargar
                    <StyledInputFile
                      id="EdoCuenta64"
                      name="EdoCuenta"
                      type="file"
                      accept="application/pdf"
                    />
                  </StyledButtonEditar>
                ) : (
                  values.EdoCuenta64 && (
                    <StyledButtonEditar
                      size="medium"
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      endIcon={<PictureAsPdfIcon />}
                      id="EdoCuenta64"
                      name="EdoCuenta64"
                      onClick={(e) =>
                        createFile(values.EdoCuenta64, "application/pdf")
                      }
                    >
                      Ver
                    </StyledButtonEditar>
                  )
                )}
              </StyledDiv>
            )}
            {/* Opinión de cumplimiento de obligación fiscal */}

            <StyledDiv>
              <Typography
                align="left"
                variant="subtitle2"
                gutterBottom
                sx={window.innerWidth > 500 && { width: 1 / 2 }}
              >
                {values.ValObligacionFiscal === "Y" ? "*" : ""}Opinión de
                cumplimiento de obligación fiscal:
              </Typography>
              <TextField
                fullWidth
                align="left"
                autoComplete="section-Proveedor-ObligacionFiscal"
                size="small"
                variant="outlined"
                required
                id="ObligacionFiscal"
                name="cumplObligacionFiscalimiento"
                value={values.ObligacionFiscal}
                disabled={true}
                helperText={
                  <>
                    <label>Restan {values.DiasObligacionFiscal} dias</label>
                    <sup>1</sup>
                  </>
                }
              />
              {operador === "Proveedor" ? (
                <StyledButtonEditar
                  size="medium"
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  endIcon={<CloudUploadIcon />}
                  id="cumplimiento"
                  name="cumplimiento"
                  onChange={handleChange}
                  multiple={false}
                >
                  cargar
                  <StyledInputFile
                    id="ObligacionFiscal64"
                    name="ObligacionFiscal"
                    type="file"
                    accept="application/pdf"
                  />
                </StyledButtonEditar>
              ) : (
                values.ObligacionFiscal64 && (
                  <StyledButtonEditar
                    size="medium"
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    endIcon={<PictureAsPdfIcon />}
                    id="ObligacionFiscal64"
                    name="ObligacionFiscal64"
                    onClick={(e) =>
                      createFile(values.ObligacionFiscal64, "application/pdf")
                    }
                  >
                    Ver
                  </StyledButtonEditar>
                )
              )}
            </StyledDiv>

            {/* Documentacion adicional */}

            <StyledDiv>
              <Typography
                align="left"
                variant="subtitle2"
                gutterBottom
                sx={window.innerWidth > 500 && { width: 1 / 2 }}
              >
                Carátula bancaria:
              </Typography>
              <TextField
                fullWidth
                align="left"
                autoComplete="section-Proveedor-DocAdicional"
                size="small"
                variant="outlined"
                required
                id="DocAdicional"
                name="DocAdicional"
                value={values.DocAdicional}
                disabled={true}
              />
              {operador === "Proveedor" ? (
                <StyledButtonEditar
                  size="medium"
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  endIcon={<CloudUploadIcon />}
                  id="DocAdicional"
                  name="DocAdicional"
                  onChange={handleChange}
                  multiple={false}
                >
                  cargar
                  <StyledInputFile
                    id="DocAdicional64"
                    name="DocAdicional"
                    type="file"
                    accept="application/pdf"
                  />
                </StyledButtonEditar>
              ) : (
                values.DocAdicional64 && (
                  <StyledButtonEditar
                    size="medium"
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    endIcon={<PictureAsPdfIcon />}
                    id="DocAdicional64"
                    name="DocAdicional64"
                    onClick={(e) =>
                      createFile(values.DocAdicional64, "application/pdf")
                    }
                  >
                    Ver
                  </StyledButtonEditar>
                )
              )}
            </StyledDiv>

            <StyledDiv>
              <Typography
                align="left"
                variant="caption"
                gutterBottom
                color="textSecondary"
                sx={{ display: "block" }}
              >
                (1): Actualizar obligatoriamente antes de que caduque
              </Typography>
            </StyledDiv>
          </Grid>
        </StylePaperPrincipal>
      </Grid>
    </>
  );
}

Archivos.propTypes = {
  values: PropTypes.object,
};

export default Proveedor;
